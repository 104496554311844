import React from "react"

import Fade from "react-reveal/Fade"

import "../style/pages/video.scss"


const Video = ({youtube}) => {
  return(
    <div className="video-section flex justify-center items-center" >
      <div className="youtube flex flex-col justify-center items-center">
        <Fade bottom>
            <div
                dangerouslySetInnerHTML={{ __html: youtube }}
            />
        </Fade>
      </div>
    </div>
  )
}

export default Video

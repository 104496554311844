import React from "react"
import { graphql } from "gatsby"

import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

import MusicMedia from "../components/musicMedia"
import Infos from "../components/infos"
import About from "../components/about"
import Video from "../components/video"
import Instagram from "../components/instagram"

import Fade from "react-reveal/Fade"

import "../style/pages/home.scss"

var uniqid = require('uniqid');

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Home" />
    <div className="main-large-screen hidden lg:block relative bg-home-main">
        {data.about.edges.map(({ node }) => (
            <div key={uniqid()}>
                <Image alt={node.frontmatter.mainImage}  filename={node.frontmatter.mainImage} />
                <Header siteTitle={data.site.siteMetadata.title} />
                <div style={{margin: `0 auto`, zIndex: 2 }} className="h-main-home flex flex-row items-center">
                    <Fade left>
                        <div style={{padding: `0 3.0875rem`}} className="flex flex-col justify-end items-center w-1/2 h-full mt-20 mb-0">
                            <About
                                key={uniqid()}
                                bio={node.html}
                            />
                        </div>
                    </Fade>
                    <Fade right>
                        <div style={{padding: `0 3.0875rem`}} className="flex flex-col justify-start w-1/2 h-full mb-0 mt-0 text-left">
                            <h3 className="uppercase text-center w-3/4 text-2xl">{node.frontmatter.aboutTitle}</h3>
                            <MusicMedia />
                        </div>
                    </Fade>
                </div>
            </div>
        ))}
        <div className="w-screen mt-10">
            {data.release.edges.map(({ node }) => (
                <Infos
                  key={uniqid()}
                  lastReleaseImage={node.frontmatter.lastReleaseImage}
                  lastRelease={node.frontmatter.lastRelease}
                  lastReleaseName={node.frontmatter.lastReleaseName}
                  lastReleaseBio={node.frontmatter.lastReleaseBio}
                  newsImage={node.frontmatter.newsImage}
                  newsBio={node.frontmatter.newsBio}
                />
            ))}
        </div>
    </div>
    <div className="main-mobile lg:hidden relative bg-home-main">
        <div style={{margin: `0 auto`, zIndex: 2 }} className="h-main-home flex flex-col items-center">
            <div style={{height: 450}} className="relative">
                {data.about.edges.map(({ node }) => (
                    <div key={uniqid()}>
                        <Image key={uniqid()} alt={node.frontmatter.mainImage}  filename={node.frontmatter.mainImage} />
                        <Header key={uniqid()} siteTitle={data.site.siteMetadata.title} />
                        <Fade key={uniqid()} left>
                            <div style={{padding: `0 3.0875rem`}} className="flex flex-col w-full mt-24 mb-0">
                                <h3 className="uppercase text-center large-screen text-xl">{node.frontmatter.aboutTitle}</h3>
                                <MusicMedia key={uniqid()} />
                            </div>
                        </Fade>
                    </div>
                ))}
            </div>
            <div className="w-screen">
            {data.release.edges.map(({ node }) => (
                <Infos
                  key={uniqid()}
                  lastReleaseImage={node.frontmatter.lastReleaseImage}
                  lastRelease={node.frontmatter.lastRelease}
                  lastReleaseName={node.frontmatter.lastReleaseName}
                  lastReleaseBio={node.frontmatter.lastReleaseBio}
                  newsImage={node.frontmatter.newsImage}
                  newsBio={node.frontmatter.newsBio}
                />
            ))}
            </div>
            <Fade right>
                <div style={{padding: `0 3.0875rem`}} className="flex flex-col w-full h-full my-20">
                    {data.about.edges.map(({ node }) => (
                        <About
                        key={uniqid()}
                        bio={node.html}
                        />
                    ))}
                </div>
            </Fade>
        </div>
    </div>
    {data.video.edges.map(({ node }) => (
        <Video
            key={uniqid()}
            youtube={node.html}
        />
    ))}
    <div className="instagram-section">
        <Instagram />
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
query indexQuery {
  site {
    siteMetadata {
      title
    }
  }
  about: allMarkdownRemark(
    filter: {frontmatter: {content: {eq: "about"}}}
  ) {
    edges {
      node {
        html
        frontmatter {
            mainImage
            aboutTitle
        }
      }
    }
  }
  release: allMarkdownRemark(
    filter: {frontmatter: {content: {eq: "release"}}}
  ) {
    edges {
      node {
        html
        frontmatter {
            lastReleaseImage
            lastRelease
            lastReleaseName
            lastReleaseBio
            newsImage
            newsBio
        }
      }
    }
  }
  video: allMarkdownRemark(filter: {frontmatter: {content: {eq: "youtube"}}}) {
    edges {
      node {
        html
      }
    }
  }
}
`

import React from "react"

import Image from "./image"

const Release = ({lastReleaseImage, lastRelease, lastReleaseName, lastReleaseBio, newsImage, newsBio}) => {
  return(
    <>
      <div className="flex flex-col lg:flex-row items-center justify-between">
            <div className="flex flex-row-reverse lg:flex-row justify-center w-full lg:w-1/2">
                <div className="relative card-img event-img w-1/2">
                    <Image alt={newsImage}  filename={newsImage} />
                </div>
                <div className="div-blur flex w-1/2">
                    <div className="flex flex-col p-3">
                        <h6 className="mb-2 p-1 uppercase bg-white" style={{width: `fit-content`, color: `#1A1A19`}}>News</h6>
                        <p className="my-auto text-tiny font-normal">
                            {newsBio}
                        </p>
                    </div>
                </div>
                </div>
                <div className="flex flex-row lg:flex-row justify-center w-full lg:w-1/2">
                    <div className="relative card-img release-img w-1/2">
                        <Image alt={lastReleaseImage}  filename={lastReleaseImage} />
                    </div>
                    <div className="div-blur flex w-1/2">
                        <div className="flex flex-col p-3">
                          <h6 className="mb-2 p-1 uppercase bg-white" style={{width: `fit-content`, color: `#1A1A19`}}>New release : {lastReleaseName}</h6>
                          <p className="my-auto text-tiny font-normal">
                            {lastReleaseBio}
                          </p>
                        <a className="m-0 text-tiny font-bold uppercase" href={lastRelease} style={{width: `fit-content`, fontSize: `0.55rem`}}>link &rarr;</a>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Release

import React from "react"

const About = ({bio}) => {
  return(
    <>
      <div
        className="flex flex-col justify-center large-screen about-bio text-tiny text-justify lg:mb-10"
        dangerouslySetInnerHTML={{ __html: bio }}
      />
    </>
  )
}

export default About

import React from "react"
import { Grid } from "@horacioh/gatsby-theme-instagram"
import { SimpleCard } from "@horacioh/gatsby-theme-instagram"

const Instagram = () => (
  <div className="py-20 instagram-section">
    <h4 className="text-center mx-auto mb-2 p-1 uppercase bg-white font-normal">Instagram</h4>
    <p className="text-center">Follow us <a href="https://www.instagram.com/brothersevolution/" className="text-gray-500">@brothersevolution</a></p>
    <Grid card={SimpleCard}/>
  </div>
)

export default Instagram
